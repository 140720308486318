@mixin rounded($height) {
	border-radius: $height / 2;
}

@mixin input {
	display: inline-block;
	background-color: white;
	padding: 0 15px;
	line-height: 30px;
	border: none;
	@include rounded(30px);
}

@mixin input-merged {
	display: flex;

	.input-merged-left,
	.input-merged-right {
		@include input;
		margin-left: 0;
		margin-left: 0;
		margin-right: 0;
	}

	.input-merged-left {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.input-merged-right {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

@mixin icon-left {
	padding-left: 30px;
}

@mixin icon-right {
	padding-right: 30px;
}

@mixin input-icon {
	display: flex;
	position: absolute;
	width: 30px;
	height: 100%;
	justify-content: center;
	align-items: center;
	z-index: 2;
}

@mixin cover {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

@mixin background {
	background-position: center;
	background-size: cover;
}

@mixin hover-shadow {
	box-shadow: 0 0 15px $box-shadow-color;
}

@mixin text-shadow {
	text-shadow: 0 0 2px rgba(black, 0.75), 0 0 6px rgba(black, 0.75);
}
