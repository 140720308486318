@import './breakpoints';
@import './mixins';

.select {
	@include input;
	position: relative;
	display: inline-block;
	height: 30px;
	padding: 0;
	overflow: visible;
	cursor: pointer;
	transition-duration: 0.3s;

	@include icon-right;

	.icon {
		@include input-icon;
		right: 0;
	}

	.select-active {
		width: 100%;
		padding: 0 15px;
	}

	.select-list {
		display: block;
		position: absolute;
		left: 0;
		top: 30px;
		width: 100%;
		max-height: 300px;
		overflow: auto;
		pointer-events: none;
		opacity: 0;
		transition-duration: 0.15s;

		.select-option {
			display: block;
			padding: 0 5px 0 25px;
			background-color: white;
			border-top: solid 1px #aaa;
			line-height: 35px;
		}
	}

	&.open {
		position: relative;
		border-radius: 0;
		z-index: 8;

		.select-list {
			pointer-events: all;
			opacity: 1;
			transition-duration: 0.15s;
		}
	}
}

.date {
	position: relative;
	border: none;
	cursor: pointer;
	transition-duration: 0.3s;
	@include input;
	@include icon-right;

	.icon {
		@include input-icon;
		right: 0;
	}

	input[type='date'].inactive {
		display: none;
	}
}

.checkbox {
	label {
		display: flex;
		width: 100%;
		align-items: center;
		cursor: pointer;
	}

	.checkbox-box {
		display: inline-block;
		position: relative;
		margin-right: 10px;
		width: 20px;
		height: 20px;
		background-color: white;
		border-radius: 2px;
		transition-duration: 0.15s;

		&.active {
			background-color: black;
		}

		&::before {
			content: '\2713';
			display: flex;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			color: #fff;
			line-height: 0;
			justify-content: center;
			align-items: center;
			opacity: 0;
		}

		&.active::before {
			opacity: 1;
			transition-duration: 0.15s;
		}
	}

	input[type='checkbox'].inactive {
		display: none;
	}
}

.input-number {
	margin: 0;
	width: 100%;
	height: 30px;
	padding: 0 15px;
	border: none;
	border-radius: 15px;
	transition-duration: 0.3s;
}

.input-number:focus {
	border-radius: 0;
}
