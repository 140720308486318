$breakpoints: (
	xxl: 1399px,
	xl: 1199px,
	lg: 991px,
	md: 767px,
	sm: 575px,
	xs: 400px
);

// Screen breakpoints: [xxl, xl, lg, md, sm, xs]
@mixin respond-to($breakpoint) {
	@media (max-width: #{map-get($breakpoints, $breakpoint)}) {
		@content;
	}
}
// Screen breakpoints: [xxl, xl, lg, md, sm, xs]
@mixin respond-to-height($breakpoint) {
	@media (max-height: #{map-get($breakpoints, $breakpoint)}) {
		@content;
	}
}
